/* eslint-disable dot-notation */
import styled, { withTheme } from 'styled-components';

const DashboardContactImage = styled.div`
    width:116px;
    height:116px;
    line-height:90px;
    text-align: center;
    background-color: ${props => props.theme['$white']};

    img {
        width:100%;
        height:auto;
        display:inline-block;
    }
`;

export default withTheme(DashboardContactImage);
