
import styled, { withTheme } from 'styled-components';
import iconProfile from '../../../assets/images/icon-profile.svg';
import iconProfileWhite from '../../../assets/images/icon-profile-white.svg';
import imgBrand from '../../../assets/images/logo-brand.svg';
import imgBrandGreyMuted from '../../../assets/images/logo-brand-gray-muted.svg';

const BrandIcon = styled.span`
    display: inline-block;
    vertical-align:middle;
    &.profile {
        width: 24px;
        height: 24px;
        background: url(${iconProfile}) no-repeat center center;
        background-size: 24px 24px;
    }
    &.profile-white {
        width: 24px;
        height: 24px;
        background: url(${iconProfileWhite}) no-repeat center center;
        background-size: 24px 24px;
    }
    &.brand-md {
        width: 102px;
        height: 51px;
        background: url(${imgBrand}) no-repeat center center;
        background-size: 102px 51px;
    }
    &.brand-lg {
        width: 136px;
        height: 68px;
        background: url(${imgBrand}) no-repeat center center;
        background-size: 136px 68px;
    }
    &.brand-md-muted {
        width: 102px;
        height: 51px;
        background: url(${imgBrandGreyMuted}) no-repeat center center;
        background-size: 102px 51px;
    }
    &.brand-lg-muted {
        width: 136px;
        height: 68px;
        background: url(${imgBrandGreyMuted}) no-repeat center center;
        background-size: 136px 68px;
    }
`;

export default withTheme(BrandIcon);
